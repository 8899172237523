<template>
    <div class="articles">
        <banner></banner>
        <div class="site-content animate">
            <main class="site-main">
                <article class="hentry">
                    <!-- 文章头部 -->
                    <header class="entry-header">
                        <!-- 标题输出 -->
                        <h1 class="entry-title">MockJs</h1>
                        <hr>
                        <div class="breadcrumbs">
                            <div id="crumbs">最后更新时间：2021年05月04日</div>
                        </div>
                    </header>
                    <!-- 正文输出 -->
                    <div class="entry-content">
                        <p>@[TOC]</p>
                        <h4 id="引言：">引言：</h4>
                        <p>mockjs简单实用案例，外加body传参数据请求！！！</p>
                        <blockquote>
                          1.安装mockjs
                        </blockquote>
                        <hr>
                        <pre><code>cnpm install mockjs --save-dev  // cnpm是安装好nodejs之后最好再安装淘宝镜像cnpm</code></pre>

                        <blockquote>
                          2.创建mock.js文件  // 文件名字自己自定义即可
                        </blockquote>
                        <hr>
                        <pre><code>
        // 简单的返回示例
        //引入mockjs
        import Mock from 'mockjs'
        Mock.mock('api/datalist', {
            code:0,
            data: [
                {
                    name: '张三',
                    age: 15
                },
                {
                    name: '李四',
                    age: 18
                },
            ]
        });
                        </code></pre>
                        <pre><code>
        // 这是一个传参获取数据的例子，参照此案例自己写逻辑
        import Mock from 'mockjs'
        Mock.mock(
            RegExp('api/datalist' + ".*"),
            'post',
            option=> {
                const { payload } = JSON.parse(option.body)
                console.log(option);
                if(payload === 666) {
                    return {
                        code: 0,
                        data: [
                            {
                                name: '张三',
                                age: 15
                            },
                            {
                                name: '李四',
                                age: 18
                            },
                        ]
                    }
                }else {
                    return {
                        code: -1,
                        data: '参数错误'
                    }
                }
            },
        )    
                        </code></pre>

                        <blockquote>
                          3.在main.js中引入mockjs
                        </blockquote>
                        <hr>
                        <pre><code>import './mock'  // 这里就是引入你创建的mockjs文件路径</code></pre>
                        <blockquote>
                          4.在想要使用接口的文件中直接使用！
                        </blockquote>
                        <hr>
                        <pre><code>
        // axios安装使用这里不做讲解了，百度一下，我是直接使用的原生请求，没有去封装post get请求
        let payload = 666
        this.axios.post('api/datalist', {
            payload
        }).then(res=> {
            console.log(res.data)
            this.dataList(res.data.data)
        })    
                        </code></pre>
                    </div>
                    <!-- 文章底部 -->
                    <section-title>
                        <footer class="post-footer">
                            <!-- 阅读次数 -->
                            <div class="post-like">
                                <i class="iconfont iconeyes"></i>
                                <span class="count">685</span>
                            </div>
                            <!-- 文章标签 -->
                            <div class="post-tags">
                                <i class="iconfont iconcategory"></i>Web
                            </div>
                        </footer>
                    </section-title>
                    <!--声明-->
                    <div class="open-message">
                        <p>ps：文章中如有什么问题，请点击下方QQ联系我!!!   欢迎来搞，感谢支持！！！</p>
                    </div>
                </article>
            </main>
        </div>
    </div>
</template>

<script>
    import Banner from '@/components/banner'
    import sectionTitle from '@/components/section-title'
    import './css/articles.less'
    export default {
        name: 'articles',
        data(){
          return{
              showDonate: false,
              menus: []
          }
        },
        components: {
            Banner,
            sectionTitle,
        },
        methods: {
         
        },
        mounted(){
            
        },
        created() {
            
        }
    }
</script>
